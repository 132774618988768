import { Component, OnInit, Input } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { StoreItem } from 'src/app/classes';
import { Store } from 'src/app/enums';

@Component({
  selector: 'app-store-item',
  templateUrl: './store-item.component.html',
  styleUrls: ['./store-item.component.scss']
})
export class StoreItemComponent implements OnInit {

  @Input() storeItem: StoreItem;

  stores = Store;

  itemNames = {
    kit_full: "Full Kit",
    headset: "Headset",
    controllers: "Controllers",
    basestation: "Basestation",
    gasket: "Gasket",
    kit_hmd_and_controllers: "Headset + Controllers Kit"
  };

  constructor(
    public configService: ConfigService
  ) { }

  ngOnInit() {
  }

  toggleNotifyFor(itemName: string) {
    this.configService.config.notifyItems[itemName] = !this.configService.config.notifyItems[itemName];
    this.configService.save();
  }

}
