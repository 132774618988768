import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restock-timer',
  templateUrl: './restock-timer.component.html',
  styleUrls: ['./restock-timer.component.scss']
})
export class RestockTimerComponent implements OnInit {

  restockDate: number = 1583773200000;

  constructor() { }

  ngOnInit() {
  }

}
