import { CheckItem } from './check-item';

export class StoreItem {
  results: CheckItem[] = [];
  current = new CheckItem();
}

export class ItemStore {
  kit_full = new StoreItem();
  headset = new StoreItem();
  controllers = new StoreItem();
  basestation = new StoreItem();
  gasket = new StoreItem();
  kit_hmd_and_controllers = new StoreItem();
}
