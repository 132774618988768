import { Store, Language, DeviceType } from '../enums';
import { AlarmLevel } from '../enums/alarm-level.enum';

export class ChannelSubscriptions {
  de: boolean = false;
  us: boolean = false;
  jp: boolean = false;
  test: boolean = false;
}

export class NotifyItems {
  headset: boolean = true;
  controllers: boolean = true;
  basestation: boolean = true;
  gasket: boolean = false;
  kit_full: boolean = true;
  kit_hmd_and_controllers: boolean = true;
}

export class ClientConfig {
  store: Store = Store.DE;
  device: DeviceType = DeviceType.Undefined;
  language: Language = Language.EN;
  notifications: boolean = false;
  notifyItems = new NotifyItems();
  notifyLevel: AlarmLevel = AlarmLevel.Medium;
  volume: number = 0.5;
  expanded: boolean = true;
  subscriptionHash: string = "";
  channelSubscriptions = new ChannelSubscriptions();
  showDisconnect: boolean = true;
}
