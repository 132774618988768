import { AlarmLevel } from '../enums/alarm-level.enum';

export class CheckItem {
  name: string;
  subid: number;
  checksum: string;
  canBuy: boolean = false;
  lineCount: number;
  thisServerTime: number;
  g_ServerTime: number;
  canBuyChanged: boolean;
  checksumChanged: boolean;
  lineCountChanged: boolean;
  alarmLevel: AlarmLevel;
}
