import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';
import { SubscriptionPackage } from '../classes';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private PUSH_API: string = 'https://api.valveindex.ua.ms:9017';

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  addPushSubscriber(subscription: PushSubscription, subHash: string, channel: string) {
    let body: SubscriptionPackage = {
      channel: channel,
      sub: subscription,
      subHash: subHash
    };
    return this.http.post(this.PUSH_API + '/api/addSub', body);
  }

  removePushSubscriber(subHash: string, channel: string) {
    let body = {
      channel: channel,
      subHash: subHash
    };
    return this.http.post(this.PUSH_API + '/api/removeSub', body);
  }
}
