import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

import { CheckItem, ItemStore, ApiUpdate } from '../classes';
import { Store } from '../enums';
import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class ServerService {
  private ports = environment.ports;

  private checkConnectionInterval;
  private socket: SocketIOClient.Socket;
  private interrupted: boolean = false;
  public connected: boolean = false;
  public apiVersion: string = environment.apiVersion;

  public updateAvailable = new EventEmitter<boolean>();
  public randomNumber: number = Math.floor(Math.random() * 1000);

  constructor(
    private configService: ConfigService
  ) { }

  connectToAPI(store: Store) {
    this.connected = false;
    clearInterval(this.checkConnectionInterval);

    if (this.socket) {
      console.log('disconnect from API ' + this.socket.io.uri);
      this.socket.close();
    }
    
    this.socket = io('https://api.valveindex.ua.ms:' + this.ports[store].port);
    console.log('connecting to API ' + this.socket.io.uri);

    this.checkConnectionInterval = setInterval(() => {
      this.checkConnection();
    }, 20000);

    this.socket.on('connect', () => {
      this.connected = true;
    });
  }

  private checkConnection() {
    let changed = this.interrupted != this.socket.disconnected;
    this.interrupted = this.socket.disconnected;
    this.connected = !this.interrupted;
    if (changed) {
      console.log('Server connected: ' + this.connected);
      let msg = this.connected ? 'Connected' : 'Disconnected';
      
      if (Notification.permission === "granted" && this.configService.config.showDisconnect) {
        var notification = new Notification("VIAC - Server Info", {
          body: msg
        });
      }
    }
  }

  // EMITTER
  requestTestAlarm() {
    this.socket.emit('requestTestAlarm');
  }

  // HANDLER
  onPreviouseResults(): Observable<ItemStore> {
    return Observable.create(observer => {
      this.socket.on('previousResults', (store: ItemStore) => {
        Object.keys(store).forEach(key => {
          let item = store[key];
          let resultsCount = item.results.length;
          store[key].current.g_ServerTime = store[key].current.g_ServerTime * 1000;
          for (let i = 0; i < resultsCount; i++) {
            store[key].results[i].g_ServerTime = store[key].results[i].g_ServerTime * 1000;
          }
        });
        observer.next(store);
      });
    });
  }

  onCurrentState(): Observable<CheckItem> {
    return Observable.create(observer => {
      this.socket.on('currentState', (result: CheckItem) => {
        result.g_ServerTime = result.g_ServerTime * 1000;
        observer.next(result);
      });
    });
  }

  onAlarm(): Observable<CheckItem> {
    return Observable.create(observer => {
      this.socket.on('alarm', (result: CheckItem) => {
        result.g_ServerTime = result.g_ServerTime * 1000;
        observer.next(result);
      });
    });
  }

  onApiUpdateAvailable(): Observable<ApiUpdate> {
    return Observable.create(observer => {
      this.socket.on('updateAvailable', (result: ApiUpdate) => {
        if (result.availableVersion != this.apiVersion) {
          observer.next(result);
        }
      });
    });
  }

  onApiVersionChanged(): Observable<any> {
    return Observable.create(observer => {
      this.socket.on('runningVersion', (data) => {
        if (data != this.apiVersion) {
          observer.next(data);
        }
      });
    })
  }
}
