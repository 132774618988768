import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ServerService } from './services/server.service';
import { HomeComponent } from './pages/home/home.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { VersionInfoComponent } from './components/version-info/version-info.component';
import { ConfigComponent } from './components/config/config.component';
import { ConfigService } from './services/config.service';
import { LocalStorageService } from './services/local-storage.service';
import { StoreItemComponent } from './components/store-item/store-item.component';
import { MoreAboutComponent } from './components/more-about/more-about.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { VersionComponent } from './pages/version/version.component';
import { RestockTimerComponent } from './components/restock-timer/restock-timer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EnumToArrayPipe,
    KeysPipe,
    FooterComponent,
    InfoDialogComponent,
    VersionInfoComponent,
    ConfigComponent,
    StoreItemComponent,
    MoreAboutComponent,
    ContactComponent,
    AboutComponent,
    VersionComponent,
    RestockTimerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppMaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ServerService,
    ConfigService,
    LocalStorageService
  ],
  entryComponents: [
    InfoDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
