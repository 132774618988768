import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReleaseNote } from '../classes/release-note';
import { version } from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  releaseNotes: ReleaseNote[] = [];
  clientVersion: string = version;

  constructor(
    private http: HttpClient
  ) {
    this.http.get<ReleaseNote[]>('./assets/release-notes.json').subscribe(data => {
      this.releaseNotes = data.reverse();
    });
  }

}
