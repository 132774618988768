import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwPush } from '@angular/service-worker';
import { Observable, of, concat } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5'; 


import { ConfigService } from 'src/app/services/config.service';
import { AlarmLevel } from 'src/app/enums';
import { ServerService } from 'src/app/services/server.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { environment } from '../../../environments/environment';
import { ChannelSubscriptions } from 'src/app/classes';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  @Output() serverChanged = new EventEmitter<any>();

  readonly VAPID_PUBLIC_KEY = "BG-MmzK50S84338uxfTnjDhcTth8x5Y3QPbu6T5PEPR5A_Jtro3RzuOktmwTyuPnrJBqRDAPt3iL3kpfJIM2xRk";

  alarmLevel = AlarmLevel;
  enableNotificationPossible: boolean = true;
  disabledForReply: boolean = false;
  tempDisabledSubscrCheckbox: ChannelSubscriptions = { de: false, us: false, jp: false, test: false };
  switchServerDisabled: boolean = false;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private swPush: SwPush,
    private server: ServerService,
    private pnService: PushNotificationService,
    public configService: ConfigService,
  ) { }

  ngOnInit() {
    if (Notification['permission'] === 'denied') {
      this.enableNotificationPossible = false;
    }

    // if (this.configService.config.subscriptionHash.length > 0) {
    //   if (this.swPush.isEnabled) {
    //     this.swPush.subscription.subscribe(sub => {
    //       if (sub !== null) {
            
    //       }
    //     });
    //   }
    // }
  }

  onConfigChanged() {
    console.log(this.configService.config);
    this.configService.save();
  }

  onServerChanged() {
    this.switchServerDisabled = true;
    this.onConfigChanged();
    this.serverChanged.emit(true);
    setTimeout(() => {
      this.switchServerDisabled = false;
    }, 5000);
  }

  enableNotifications() {
    // NEW
    if (environment.production) {
      this.disabledForReply = true;

      this.swPush.subscription.subscribe(sub => {
        if (sub === null) {
          this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
          }).then(sub => {
            this.enableOnlyBrowserNotifications();
            if (Notification['permission'] === 'granted') {
              this.configService.config.notifications = true;
              if (sub !== null) {
                this.generateAndSaveSubHash(sub);
              }
              this.onConfigChanged();
            }
            this.disabledForReply = false;
          }).catch(err => {
            this.enableOnlyBrowserNotifications();
            console.error("Could not subscribe to swPush notifications", err);
          });
        } else {
          if (Notification['permission'] === 'granted') {
            this.configService.config.notifications = true;
            if (sub !== null) {
              this.generateAndSaveSubHash(sub);
            }
            this.onConfigChanged();
          }
          this.disabledForReply = false;
        }
      },
      err => {
        this.enableOnlyBrowserNotifications();
      });
    } else {
      this.enableOnlyBrowserNotifications();
    }
  }

  generateAndSaveSubHash(sub: PushSubscription) {
    if (this.configService.config.subscriptionHash.length < 6) {
      let subHash = Md5.hashStr(JSON.stringify(sub)).toString();
      if (subHash.length < 6) {
        let rand = Math.round(Math.random() * 10000);
        subHash = 'foo' + rand + Date.now();
      }
      this.configService.config.subscriptionHash = subHash;
      this.configService.save();
    }
  }

  enableOnlyBrowserNotifications() {
    if("Notification" in window) {
      if (Notification['permission'] === 'default') {
        Notification.requestPermission().then((permission) => {
          if(permission === 'denied' || permission === 'default') {
            this.disableNotifications();
            this.enableNotificationPossible = false;
          } else {
            this.configService.config.notifications = true;
          }
          this.onConfigChanged();
          this.disabledForReply = false;
        });
      } else if(Notification['permission'] === 'granted') {
        this.configService.config.notifications = true;
        this.onConfigChanged();
        this.disabledForReply = false;
      }
    }
  }

  disableNotifications() {
    this.configService.config.notifications = false;
    this.onConfigChanged();
    this.disabledForReply = true;
 
    if (environment.production) {
      let de = this.unsubscribeFromChannel('de');
      let us = this.unsubscribeFromChannel('us');
      let jp = this.unsubscribeFromChannel('jp');
      let uk = this.unsubscribeFromChannel('uk');
      let test = this.unsubscribeFromChannel('test');
      concat(de, us, jp, uk, test).subscribe(res => {
        this.configService.config.channelSubscriptions = new ChannelSubscriptions();
        if (this.swPush.isEnabled) {
          this.swPush.subscription.subscribe(sub => {
            if (sub !== null) {
              this.swPush.unsubscribe().then(() => console.log('unsubscribed swPush')).catch(err => console.log('error in swPush unsubscribe: ', err));
            }
            this.configService.config.subscriptionHash = '';
            this.configService.config.notifications = false;
            this.configService.save();
            this.disabledForReply = false;
          });
        } else {
          this.configService.config.subscriptionHash = '';
          this.configService.config.notifications = false;
          this.configService.save();
          this.disabledForReply = false;
        }
      },
      err => {
        console.log('error in disableNotifications:');
        console.log(err);
        this.configService.config.subscriptionHash = '';
        this.configService.config.notifications = false;
        this.configService.save();
        this.disabledForReply = false;
        this.swPush.unsubscribe();
      });
    } else {
      this.configService.config.notifications = false;
      this.disabledForReply = false;
      this.onConfigChanged();
    }
  }

  onChannelSubscriptionChange(channel: string, event: MatCheckboxChange) {
    console.log('onChannelSubscriptionChange: ' + channel);
    this.tempDisabledSubscrCheckbox[channel] = true;
    let subscribe: boolean = this.configService.config.channelSubscriptions[channel];
    
    console.log(subscribe);

    let subChangeObservable: Observable<boolean>;
    if (subscribe) {
      subChangeObservable = this.subscribeToChannel(channel);
    } else {
      subChangeObservable = this.unsubscribeFromChannel(channel);
    }

    subChangeObservable.subscribe(success => {
      if (success === false) {
        this.configService.config.channelSubscriptions[channel] = !subscribe;
        event.source.checked = !subscribe;
        this.snackBar.open("The subscription could not be changed. Try again in a few minutes.\nSee Help if the issue persists.", null, {
          duration: 10000
        });
      } else if(Notification['permission'] === 'granted') {
        this.configService.config.notifications = true;
        this.onConfigChanged();
      }
      this.tempDisabledSubscrCheckbox[channel] = false;
      this.onConfigChanged();
    },
    error => {
      console.log("error in subChangeObservable:");
      console.log(error);
    });
  }

  private subscribeToChannel(channel: string) { // Observable<boolean> 
    console.log('subscribeToChannel begin');
    if (this.swPush.isEnabled) {
      return this.swPush.subscription.pipe(
        flatMap(sub => {
          console.log('foooo');
          if (sub !== null) {
            this.generateAndSaveSubHash(sub);
            let subHash = this.configService.config.subscriptionHash;
            console.log("swPush subscription found. subHash = " + subHash);
            return this.pnService.addPushSubscriber(sub, subHash, channel);
          } else {
            console.log('no swPush subscription found');
            return Observable.create({});
          }
        }),
        map(res => {
          console.log("subscribeToChannel result:");
          return res && res['success'] && res['success'] === true;
        }),
        catchError(err => {
          console.log(err);
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }

  private unsubscribeFromChannel(channel: string): Observable<boolean> {
    let subHash = this.configService.config.subscriptionHash;
    if (subHash != '') {
      return this.pnService.removePushSubscriber(subHash, channel).pipe(
        map(res => {
          console.log("unsubscribeFromChannel result:");
          console.log(res);
          return res && res['success'] && res['success'] === true;
        }),
        catchError(err => {
          console.log('error in unsubscribeFromChannel:');
          console.log(err);
          return of(false);
        })
      )
    } else {
      console.log('no subHash found');
      return Observable.create(false);
    }
  }

  requestTestAlarm() {
    setTimeout(() => {
      console.log('requested test alarm');
      this.server.requestTestAlarm();
    }, 5000);
  }

  openInfoDialogStore() {
    this.dialog.open(InfoDialogComponent, {
      maxWidth: 800,
      data: {
        text: `
        Steam/Valve ships all Hardware orders either from a warehouse in the United States located in Carol Stream, Illinois, or from a warehouse in the European Union located in Tilburg, the Netherlands.<br>
        There is also a third location, operated by a partner company (Degica), for orders from Japan.<br>
        <br>
        Therefore you can only choose between 3 stores on the VIAC website.<br>
        <br>
        See this Steam support website for more details:<br>
        <a href="https://support.steampowered.com/kb_article.php?ref=7077-YUOA-2321" target="_blank">Valve Hardware Shipping FAQ</a>
        `
    }});
  }

  openInfoDialog() {
    this.dialog.open(InfoDialogComponent, {
      maxWidth: 800,
      data: {
        text: `
        There are 3 priority levels of messages:
        <ul>
            <li><b>Low</b> = This message is pushed as soon as only a single character has changed in the source code of the product page. In the most cases this is caused by a template engine bug. So it is most likely a false-positiv warning. (not recommended to use)</li>
            <li><b>Medium</b> = This message is raised if the line count of the source code has changed. This may indicate that some content (product info, etc) has changed on the product page. Most times also caused by a bug.</li>
            <li><b>High</b> = This message is the most wanted one. It will only occur if the "addToCart" button/function is available. So it is 100% certain that the item is available for order.</li>
        </ul>
        With your selection you define the lowest priority level that you want to subscribe to. That means that you are subscribed to "High" in any case.`
    }});
  }

}
