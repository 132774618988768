import { Component, OnInit, ViewChild } from '@angular/core';

import { CheckItem } from 'src/app/classes/check-item';
import { ServerService } from 'src/app/services/server.service';
import { ItemStore, ApiUpdate } from 'src/app/classes';
import { Language, Store } from 'src/app/enums';
import { AlarmLevel } from 'src/app/enums/alarm-level.enum';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('refreshForm', { static: true }) refreshForm;

  clientUrl: string = environment.clientUrl;
  
  displayedColumns: string[] = ['id', 'time', 'status'];
  dataSource: CheckItem[] = [];
  audio: HTMLAudioElement;
  alarmPlaying: boolean = false;

  languages = Language;
  stores = Store;
  
  itemStore: ItemStore = new ItemStore();
  lastPull: number = 0;

  updateAvailable: boolean = false;
  availableUpdate = new ApiUpdate();
  waitForUpdate: any;

  constructor(
    public server: ServerService,
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.audio = new Audio('./assets/sounds/manhack_alert_pass1.mp3');
    this.audio.volume = this.configService.config.volume;
    this.audio.currentTime = 0;
    this.audio.addEventListener('ended', () => {
      this.audio.currentTime = 0;
      this.audio.play();
    });
    
    console.log(this.itemStore);

    this.registerServerMessages();
  }

  stopAlarm() {
    this.audio.pause();
  }

  private registerServerMessages() {
    this.server.connectToAPI(this.configService.config.store);

    this.server.onPreviouseResults().subscribe(store => {
      this.itemStore = store;
      console.log(this.itemStore);
    });

    this.server.onCurrentState().subscribe(item => {
      this.lastPull = Date.now();
      console.log("this.lastPull: " + this.lastPull);
      console.log(item);

      this.itemStore[item.name].current = item;
    });

    this.server.onApiUpdateAvailable().subscribe(item => {
      this.updateAvailable = true;
      this.availableUpdate = item;

      if (this.waitForUpdate === undefined) {
        this.waitForUpdate = setInterval(() => {
          console.log('check if update eta passed');
          if (this.availableUpdate.eta < Date.now()) {
            this.refreshForm.nativeElement.submit();
          }
        }, 60000);
      }
    });

    this.server.onApiVersionChanged().subscribe(data => {
      console.log('The API version has changed. This clients API version: ' + this.server.apiVersion + ' # Available API version: ' +  data);
      this.updateAvailable = true;
      this.availableUpdate = { availableVersion: data, message: '', eta: Date.now()};

      setTimeout(() => {
        this.refreshForm.nativeElement.submit();
      }, 10000);
    });

    this.server.updateAvailable.subscribe(() => {
      this.refreshForm.nativeElement.submit();
    });

    this.server.onAlarm().subscribe(item => {
      item.alarmLevel = AlarmLevel.Undefined;
      if ((item.canBuyChanged && item.canBuy) || item['test']) {
        item.alarmLevel = AlarmLevel.High;
      } else if (item.lineCountChanged || (item.canBuyChanged && !item.canBuy)) {
        item.alarmLevel = AlarmLevel.Medium;
      } else if (item.checksumChanged) {
        item.alarmLevel = AlarmLevel.Low;
      }

      console.log('received alarm:');
      console.log(item);

      if (!item['test']) {
        this.itemStore[item.name].results.push(item);
        this.itemStore[item.name].current = item;
      }

      if (this.configService.config.notifications) {
        let alarmLevelNames = [
          "Undefined",
          "Low",
          "Medium",
          "HIGH"
        ];
        let itemNames = {
          kit_full: "Full Kit",
          headset: "Headset",
          controllers: "Controllers",
          basestation: "Basestation",
          gasket: "Gasket",
          kit_hmd_and_controllers: "Headset + Controllers Kit"
        };

        if (Notification.permission === "granted") {
          if (item.alarmLevel >= this.configService.config.notifyLevel) {
            if (this.configService.config.notifyItems[item.name]) {
              let msg = 'Status changed!';
              let testStr = item['test'] ? ' TEST - ' : '';
              if (item.alarmLevel == AlarmLevel.High) {
                msg = itemNames[item.name] + ' is now available.\nYou can BUY IT NOW!';
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.volume = this.configService.config.volume;
                this.audio.play();
                this.alarmPlaying = true;
              }
              var notification = new Notification("VIAC - " + testStr + alarmLevelNames[item.alarmLevel] + " - " + itemNames[item.name], {
                body: msg
              });
            }
          }
        }
      }
    });
  }

  onServerChanged() {
    this.registerServerMessages();
  }

  onConfigChange() {
    this.configService.save();
  }

}
