import { Component, OnInit } from '@angular/core';

import { ServerService } from 'src/app/services/server.service';
import { VersionInfoService } from 'src/app/services/version-info.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit {

  constructor(
    public serverService: ServerService,
    public versionService: VersionInfoService
  ) { }

  ngOnInit() {
  }

}
