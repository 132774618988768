import { Injectable } from '@angular/core';

import { ClientConfig, ChannelSubscriptions } from '../classes';
import { LocalStorageService } from './local-storage.service';
import { DeviceType } from '../enums';

const storeStrings = {
  0: 'de',
  1: 'us',
  2: 'jp',
  3: 'uk'
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config = new ClientConfig();

  public storeString: string;

  constructor(
    private storageService: LocalStorageService
  ) {
    this.config = this.storageService.loadConfig();
    if (this.config.expanded === undefined) {
      this.config.expanded = true;
    }
    if (this.config.channelSubscriptions === undefined) {
      this.config.channelSubscriptions = new ChannelSubscriptions();
    }
    if (this.config.subscriptionHash === undefined) {
      this.config.subscriptionHash = "";
    }
    if (this.config.showDisconnect === undefined) {
      this.config.showDisconnect = true;
    }
    if (this.config.device === undefined) {
      this.config.device = DeviceType.Undefined;
    }
    this.storeString = storeStrings[this.config.store];
    this.save();
  }

  save() {
    this.storageService.saveConfig(this.config);
  }

}
