import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { VersionComponent } from './pages/version/version.component';


const routes: Routes = [
  { path: 'contact',    component: ContactComponent },
  { path: 'about',      component: AboutComponent },
  { path: 'version',    component: VersionComponent },
  { path: '',           component: HomeComponent },
  { path: '**',         redirectTo: '',    pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
