import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.openSnackBar("There is a new version of VIAC App available. Do you want to load it now?", "Yes", this.refresh);
      });
    }
  }

  openSnackBar(message: string, action: string, actionCallback: Function) {
    this.snackBar.open(message, action).onAction().subscribe(() => {
      actionCallback();
    });
  }

  refresh() {
    window.location.reload();
  }
  
}
