import { Component, OnInit } from '@angular/core';
import { VersionInfoService } from 'src/app/services/version-info.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  constructor(
    public versionService: VersionInfoService
  ) { }

  ngOnInit() {
  }

}
