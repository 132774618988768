import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  buttonClicked: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  clickButton() {
    this.buttonClicked = true; 
  }

}
