import { Injectable } from '@angular/core';
import { ClientConfig } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  loadConfig(): ClientConfig {
    let config = localStorage.getItem('clientConfig');
    if (config) {
      return JSON.parse(config) as ClientConfig;
    } else {
      return new ClientConfig();
    }
  }

  saveConfig(config: ClientConfig) {
    localStorage.setItem('clientConfig', JSON.stringify(config));
  }
}
